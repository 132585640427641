import ProfileService from './../services/ProfileService';

import { saveProfileGroups, profileGroupsFetchStarted, profileGroupsFetchFailed } from '../ducks/profile_groups';
import { saveProfiles, profilesFetchStarted, profilesFetchFailed } from '../ducks/profiles';
import { saveProfileStatus, clearProfileStatus, profileStatusFetchStarted, profileStatusFetchFailed } from '../ducks/profile_status';

import { createAction } from "@reduxjs/toolkit"


export const fetchProfileGroups = () => {
  return function (dispatch) {
    dispatch(profileGroupsFetchStarted());
    return ProfileService.fetchProfileGroups()
      .then((profile_groups) => {
        dispatch(saveProfileGroups(profile_groups));
      }).catch((error) => {
        console.log("ERROR FETCHING PROFILE GROUPS ", error);
        dispatch(profileGroupsFetchFailed(error));
      })
  }
}

export const fetchProfiles = () => {
  return function (dispatch) {
    dispatch(profilesFetchStarted());
    return ProfileService.fetchProfiles()
      .then((profiles) => {
        dispatch(saveProfiles(profiles));
      }).catch((error) => {
        console.log("ERROR FETCHING PROFILES ", error);
        dispatch(profilesFetchFailed(error));
      })
  }
}

export const fetchProfileStatus = (id) => {
  return function (dispatch) {
    dispatch(profileStatusFetchStarted());
    return ProfileService.fetchProfileStatus(id)
      .then((profile_status) => {
        dispatch(saveProfileStatus(profile_status));  
      }).catch((error) => {
        console.log("ERROR FETCHING PROFILES ", error);
        dispatch(profileStatusFetchFailed(error));
      })
  }
}

export const resetProfileStatus = () => {
  return function (dispatch) {
    dispatch(clearProfileStatus());
  }
}

export const profileUpdateStarted = createAction('profile/updateStarted');
export const profileUpdateFailed = createAction('profile/updateFailed');

export const updateProfile = (profile_id, params) => {
  return function (dispatch) {
    dispatch(profileUpdateStarted());
    return ProfileService.updateProfile(profile_id, params)
      .then((profile) => {
        dispatch(fetchProfileStatus(profile_id));
      }).catch((error) => {
        console.log("ERROR updating Profile ", error);
        dispatch(profileUpdateFailed(error));
      })
  }
}

export const entityDestroyStarted = createAction('entity/destroyStarted');
export const entityDestroyFailed = createAction('entity/destroyFailed');

export const destroyEntity = (profile_id, entity_id) => {
  return function (dispatch) {
    dispatch(entityDestroyStarted());
    return ProfileService.destroyEntity(profile_id, entity_id)
      .then((entity) => {
        dispatch(fetchProfileStatus(profile_id));
      }).catch((error) => {
        console.log("ERROR Destroying Entity ", error);
        dispatch(entityDestroyFailed(error));
      })
  }
}