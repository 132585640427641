import { createSlice, createAction } from "@reduxjs/toolkit"

export const loginStarted = createAction('auth/loginStarted');
export const loginSucceeded = createAction('auth/loginSucceeded');
export const loginFailed = createAction('auth/loginFailed');

const authSlice = createSlice({
  name: 'auth',
  initialState: {},
  reducers: {
    saveToken: (state, action) => { return action.payload }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = authSlice
// Extract and export each action creator by name
export const { saveToken } = actions
// Export the reducer, either as a default or named export
export default reducer
