import { Button } from '@material-ui/core';
import FusionAuthService from '../services/fusionAuthService';
import { APP_URL } from '../settings';

function LogInOut() { 
  let message = 'Sign in';
  let path = '/login';

  if(FusionAuthService.isAuthenticated()){
    message = 'Sign out';
    path = '/logout';
  }

  return(
    <Button style={{ backgroundColor: 'skyblue', color: 'white' }}>
      <a href={APP_URL + path}>{message}</a>
    </Button>
  )
}

export default LogInOut;