import { createSlice } from "@reduxjs/toolkit"

const uiSlice = createSlice({
  name: 'ui',
  initialState: { currentProfileGroup: null, currentProfile: null, currentEntity: null },
  reducers: {
    setProfileGroup: (state, action) => { 
      state.currentProfileGroup =  action.payload;
    },
    setProfile: (state, action) => {
      state.currentProfile = action.payload;
    },
    setEntity: (state, action) => {
      state.currentEntity = action.payload;
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = uiSlice
// Extract and export each action creator by name
export const { setProfileGroup, setProfile, setEntity } = actions
// Export the reducer, either as a default or named export
export default reducer