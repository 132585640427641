import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveBullet } from '@nivo/bullet'

import Paper from '@material-ui/core/Paper';
import { IMetric } from '../interfaces/interface';

import { COLORS } from '../constants/constants';

MetricBulletChart.propTypes = {
  data: PropTypes.any,
};

const COLOR_CRITICAL = COLORS.PALE_RED;
const COLOR_WARNING = COLORS.PALE_ORANGE;
const COLOR_HEALTHY = COLORS.PALE_GREEN;

function MetricBulletChart({data}: { data: IMetric }) {  

  const getRange= () => {
    if(data.direction === 'descending'){
      return [
        data.critical_threshold,
        data.warning_threshold,
        (data.max === 0 ? 0.001 : data.max) //Doesn't like zeros? Stops rendering the top color.
      ]
    }else{
      return [
        data.warning_threshold,
        data.critical_threshold,        
        (data.max === 0 ? 0.001 : data.max) //Doesn't like zeros? Stops rendering the top color.
      ]
    }
  }

  const getColorRange = () => {
    if(data.direction === 'descending'){
      return [COLOR_CRITICAL, COLOR_WARNING, COLOR_HEALTHY];
    }else if(data.direction === 'ascending'){
      return [COLOR_HEALTHY, COLOR_WARNING, COLOR_CRITICAL];
    }else{
      return [COLOR_CRITICAL, COLOR_WARNING, COLOR_HEALTHY, COLOR_WARNING, COLOR_CRITICAL];
    }

  }


  const _data = [
    {
      "id": data.name,
      "ranges": getRange(),
      "measures": [
        data.value
      ]
    }
  ];

  return (
    <Paper style={{height: 120, width: "100%", marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingRight: 20}}>
      <div style={{flex: 10, height: '100%'}}>
        <ResponsiveBullet
        data={_data}
        minValue={data.min}
        maxValue={data.max}
        margin={{ top: 50, right: 50, bottom: 50, left: 90 }}
        rangeColors={getColorRange()}
        measureColors={[COLORS.DEEP_PURPLE]}
        spacing={46}
        titleAlign="start"
        titleOffsetX={-70}
        measureSize={0.5}
    />
    </div>
    <div style={{flex: 1, fontWeight: 'bold', fontSize: '1.2em', textAlign: 'center'}}>{data.value}</div>
    </Paper>
  ); 
};

export default MetricBulletChart;
