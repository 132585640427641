import { COLORS } from '../constants/constants';

export function getColor(entity: any){
  if(entity.last_seen === null){
    return COLORS.LIGHT_GREY;
  } else{
    if(!entity.online){
       return (entity.compliant) ? "#FFDAB9" : COLORS.DIRTY_PINK;
    }else{
      return (entity.compliant) ? COLORS.PALE_GREEN : COLORS.DIRTY_PINK;
    }
  }
}