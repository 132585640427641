import React from "react";
import Typography from "@material-ui/core/Typography";
import FusionAuthLogin from "./auth-url/FusionAuthLogin";

function Login() {
  
  return (
    <>
      <FusionAuthLogin />

      <Typography
        variant="h3"
        color="inherit"
        className="font-semibold leading-tight"
      >
        Welcome <br />
        to Lumin
        <br />
      </Typography>
    </>
  );
}

export default Login;
