import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faUser
} from '@fortawesome/pro-solid-svg-icons';

// import {
//   faClock as farClock
// } from '@fortawesome/pro-regular-svg-icons';


library.add(
  faUser
);