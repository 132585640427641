import axios from "axios";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { APP_URL, PRODUCTION_LUMIN_HOST } from "../../settings";
import { AUTH_CONFIG } from "../../settings";

import { decode, authResult, token } from "../../interfaces/interface";

const ACCESS_TOKEN = "access_token_lumin_video";
const EXPIRES_AT = "expires_at";
const USER_ID = "user_id";

class FusionAuthService {
  getAuthorizationURL = async () => {
    const callbackUrl = `${APP_URL}/callback`;
    const url = `${PRODUCTION_LUMIN_HOST}/oauth/auth_url?application=v-dash&redirect_uri=${callbackUrl}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    return axios({
      method: "get",
      url,
      headers,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.response.data.error);
        } else {
          throw new Error("API Error");
        }
      });
  };

  static getHeaders() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  getToken = async (code: string) => {
    const url = `${PRODUCTION_LUMIN_HOST}/oauth/callback`;
    const headers = FusionAuthService.getHeaders();
    const callbackUrl = `${APP_URL}/callback`;
    const data = JSON.stringify({
      redirect_uri: callbackUrl,
      application: "videochat",
      code,
    });

    return axios({ method: "post", url, headers, data })
      .then((response: any) => {
        this.setSession(response.data);
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.response.data.error);
        } else {
          throw new Error("API Error");
        }
      });
  };

  login = () => {
    // redirect to login page?
    // return this.lock.show();
  };

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    if (this.isAuthTokenValid()) {
      return true;
    }
    this.logout();
    return false;
  };

  setSession = (authResult: authResult) => {
    if (authResult && authResult.access_token && authResult.userId) {
      // Set the time that the access token will expire at
      const expiresAt = JSON.stringify(
        authResult.expires_at * 1000 + new Date().getTime()
      );
      localStorage.setItem(ACCESS_TOKEN, authResult.access_token);
      localStorage.setItem(USER_ID, authResult.userId);
      localStorage.setItem(EXPIRES_AT, expiresAt);
      // this.getUserData();
    }
  };

  logout = (): string | null => {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return null;
    }
    const tokenData: token = jwtDecode(accessToken);
    const { aud: clientId } = tokenData;

    // Clear access token and ID token from local storage
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(EXPIRES_AT);

    return `${AUTH_CONFIG.domain}/oauth2/logout?client_id=${clientId}`;
  };

  isAuthTokenValid = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return false;
    }
    const decoded: decode = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      toast.warn("Access token expired");
      return false;
    }

    return true;
  };

  getUserData = async () => {
    return new Promise((resolve, reject) => {
      const fusionAuthUserUrl = `${PRODUCTION_LUMIN_HOST}/api/v3/me`;

      axios
        .get(fusionAuthUserUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getAccessToken()}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.warn("Cannot retrieve user data", error);
          reject(error);
        });
    });
  };

  getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN);
  };

  getUserId = () => {
    return window.localStorage.getItem(USER_ID);
  };

  getExpiresAt = () => {
    return window.localStorage.getItem(EXPIRES_AT);
  };
}

const instance = new FusionAuthService();

export default instance;
