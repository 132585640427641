import { configureStore } from "@reduxjs/toolkit";
import { UserSlice } from "./ducks/user";

// import { SessionSlice } from "./session/SessionSlice";
// import { DrawerSlice } from "./drawer/DrawerSlice";

import authSliceReducer from './ducks/auth';
import profilesSliceReducer from './ducks/profiles';
import profileGroupsSliceReducer from './ducks/profile_groups';
import profileStatusSliceReducer from './ducks/profile_status';
import uiSliceReducer from './ducks/ui';


const reducer = {
  auth: authSliceReducer,
  profiles: profilesSliceReducer,
  profileGroups: profileGroupsSliceReducer,
  profile_status: profileStatusSliceReducer,
  ui: uiSliceReducer,
  user: UserSlice.reducer,
  // session: SessionSlice.reducer,
}

const store = configureStore({ reducer })


export const { storeUser } = UserSlice.actions;
// export const { storeSession, storeSessionId } = SessionSlice.actions;
// export const { toggleDrawer } = DrawerSlice.actions;

export type RootState = ReturnType<typeof store.getState>;

export const selectedUser = (state: RootState) => state.user;
// export const currentSession = (state: RootState) => state.session;
// export const drawerToggleState = (state: RootState) => state.drawer;

export type AppDispatch = typeof store.dispatch;

export default store;