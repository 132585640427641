import { useEffect, useState } from "react";
import fusionAuthService from "../../services/fusionAuthService";
import { APP_URL } from "../../settings";

function Callback() {
  const [code, setCode] = useState(null);

  useEffect(() => {
    const getData = async (code: string) => {
      fusionAuthService.getToken(code).then(() => {
        // fusionAuthService.getUserData().then((userData: any) => {
          window.location.replace(APP_URL);
        // });
      });
    };

    if (fusionAuthService.isAuthenticated()) {
      return;
    }

    if (code === null) {
      const newcode = (window.location.search.match(/code=([^&]+)/) || [])[1];
      setCode(newcode);
    } else {
      getData(code);
    }
  }, [code]);

  return <></>;
}

export default Callback;
