import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import ProfileDashboard from './../components/ProfileDashboard';
import * as actionCreators from '../actions/actionCreators';
import { fetchProfileStatus, resetProfileStatus } from '../actions/actionCreators';

import {  getProfileStatus,
          getGroupedEntities } from '../selectors/profile_status';

import { setProfile } from '../ducks/ui';

import { useDispatch } from 'react-redux';


function Profile({ profile_status, history, destroyEntity, groupedEntities, updateProfile }) {
  const dispatch = useDispatch();
  let { profile_id } = useParams();

  useEffect( () => {    
    dispatch(fetchProfileStatus(profile_id));
    dispatch(setProfile(profile_id));

    return () => {
      dispatch(resetProfileStatus());
    }
  }, [dispatch, profile_id]);
    

  const _renderProgress = () => {
    return <div>
      <CircularProgress size={92} thickness={5}>
        Loading profile...
      </CircularProgress>
    </div>;
  }

  const _renderProfile = () => {
    return <ProfileDashboard
      profile={profile_status}
      history={history}
      destroyEntity={destroyEntity}
      updateProfile={updateProfile}
      groupedEntities={groupedEntities } />;
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center mb-3">
      </div>

      { profile_status ? _renderProfile() : _renderProgress() }
    </div>
  )

}


const mapStateToProps = (state, ownProps) => {
  return {
    // profile_id: ownProps.match.params.profile_id,
    profile_status: getProfileStatus(state),
    groupedEntities: getGroupedEntities(state)
  };
};


export default connect(mapStateToProps, actionCreators)(Profile);
