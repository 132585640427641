import TimelinesChart from 'timelines-chart';
import fromKapsule from 'react-kapsule';
import Paper from '@material-ui/core/Paper';
import useWindowSize from "../hooks/useWindowSize";

import { COLORS } from '../constants/constants';

import { scaleOrdinal } from "d3-scale";

export default function EventGraph(props) {
  const size = useWindowSize();
  
  const TimelinesChartComp = fromKapsule(TimelinesChart);

  const valColorScale = scaleOrdinal().domain(["Online", "Offline"]).range([COLORS.BRIGHT_GREEN, COLORS.DEEP_RED]);

  return (
    <Paper className="paper" style={{marginLeft: 0, marginRight: 0, marginTop: 50, marginBottom: 25, padding: 30 }} >
            <TimelinesChartComp
              data={props.series}
              zQualitative={true}
              zColorScale={valColorScale}
              maxLineHeight={30}
              leftMargin={50}
              rightMargin={50}
              width={size.width-50}
              // topMargin={10}
            />
      </Paper>
  );
};
