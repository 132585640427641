import moment from 'moment';

import MetricBulletChart from './MetricBulletChart';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import StarStatus from './StarStatus';

const BooleanIcon = (props) => {
  return props.check ? <CheckCircleIcon color="primary" /> : <ErrorIcon color="error" /> ;
}



function Entity({entity}) {

  function lastSeen(entity) {
    if(entity.last_seen === null){
      return "Never seen";
    } else{
      let _lastSeen = moment(entity.last_seen);
      if(!_lastSeen.isValid()){
        return "Invalid Date";
      } else {
        return _lastSeen.fromNow()
      }
    }  
  }

  function renderPropertyTableRow({id, name, value, expected_value}){
    const display_value = (value === null || value === undefined) ? "Not Present!" : value.toString();
    const display_expected_value = (expected_value === null || expected_value === undefined) ? "Not Present!"  : expected_value.toString();
    return (
      <TableRow key={id}>
        <TableCell component="th" scope="row">{name}</TableCell>
        <TableCell align="left" style={{ verticalAlign: 'center' } } >{display_expected_value}</TableCell>
        <TableCell align="right">{display_value}</TableCell>
      </TableRow>
    );
  }

  function renderPropertyTable(properties){
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Paper className="tableRoot">
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "60%" }}>Property</TableCell>
                <TableCell align="left">Expected</TableCell>
                <TableCell align="right">Actual</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {properties.map((data) => renderPropertyTableRow(data)) }
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }

  function renderLabelTableRow({id, key, value}){
    return (
      <TableRow key={id}>
        <TableCell component="th" scope="row">{key}</TableCell>
        <TableCell align="right">{value}</TableCell>
      </TableRow>
    );
  }

  function renderEntityInfo(){
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Paper className="tableRoot">
          <Table className="table">
            <TableBody>
              <TableRow>
                <TableCell>Identifier</TableCell>
                <TableCell align="right">{entity.identifier}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Online?</TableCell>
                <TableCell align="right"><BooleanIcon check={entity.online} /></TableCell>
              </TableRow>  
              <TableRow>
                <TableCell>Checkin Period</TableCell>
                <TableCell align="right">{entity.checkin_period || 'Unknown'}</TableCell>
              </TableRow>  
              <TableRow>
                <TableCell>Last Seen</TableCell>
                <TableCell align="right"><BooleanIcon check={lastSeen(entity)} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Schema</TableCell>
                <TableCell align="right">{entity.schema_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>compliant?</TableCell>
                <TableCell align="right">{entity.compliant}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>status</TableCell>
                <TableCell align="right">{entity.status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>rating</TableCell>
                <TableCell align="right"><StarStatus status={entity.rating} size='small' /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>severity</TableCell>
                <TableCell align="right">{entity.severity}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }

  function renderLabels(labels){
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Paper className="tableRoot">
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "60%" }}>Name</TableCell>
                <TableCell align="right">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {labels.map((data) => renderLabelTableRow(data)) }
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }

  return (
    <div style={{ marginTop: 50, marginLeft: 50, marginRight: 50, marginBottom: 50}} >      
       {renderEntityInfo()}
      { entity.labels.length > 0 && <>
        <h3>Labels</h3>
        {renderLabels(entity.labels)}
      </>}

      { entity.properties.length > 0 && <>
        <h3>Properties</h3>
        {renderPropertyTable(entity.properties)}
      </>}

      { entity.metrics.length > 0 && <>
        <h3>Metrics</h3>
        { entity.metrics.map((metric) => <MetricBulletChart key={metric.id} data={metric} />) }
        </>
      }

    </div>
  ); 
};

export default Entity;
