import { normalize, schema } from 'normalizr';

const profile_groups = {
  normalize(data) {
    const profileSchema = new schema.Entity('profiles');

    const profileGroupSchema = new schema.Entity('profileGroups', {
      profiles: [profileSchema]
    }
    );
    return normalize(data, [profileGroupSchema]);
  }
}

export default profile_groups;