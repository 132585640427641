import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';


import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import StarStatus from './StarStatus';
import { getColor } from '../utils/EntityStatus';

const useStyles = makeStyles(theme => ({
  row: props => ({
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    margin: 10
  }),
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));


const TrafficLightIcon = ({presence}) => {
  console.log("PRESENCE: ", presence);
  switch (presence) {
    case 'CRITICAL':
      return <ErrorIcon color="error" />;
    case 'DEGRADED':
      return <ErrorIcon color="warning" />
    default:
      return <CheckCircleIcon color="primary" />
  }
}




function EntityRow(props) {

  

  function lastSeen(entity) {
    if(entity.last_seen === null){
      return "Never seen";
    } else{
      let _lastSeen = moment(entity.last_seen);
      if(!_lastSeen.isValid()){
        return "Invalid Date";
      } else {
        return <><p>
          {_lastSeen.fromNow()}</p>
          <p><small>{_lastSeen.format('YYYY-MM-DD hh:mm:ss')}</small>
          </p>
          </>
      }
    }  
  }

  const classes = useStyles(props);
  
  return <TableRow className={classes.row} style={{backgroundColor: getColor(props.entity)}} key={props.entity.identifier} onClick={ () => { props.handleClickOpen(props.entity) }}>
                <TableCell component="th" scope="row"><strong>{props.entity.name}</strong><br/>{props.entity.name !== props.entity.identifier && props.entity.identifier}</TableCell>
                <TableCell align="center">{lastSeen(props.entity)}</TableCell>
                <TableCell align="center">
                  <div style={{display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'space-around' }}>
                  <TrafficLightIcon presence={props.entity.presence_grade} />
                  <p>{props.entity.presence_grade}</p>
                  </div>
                </TableCell>
                <TableCell align="center">{props.entity.status}</TableCell>
                <TableCell align="center"><StarStatus status={props.entity.rating} size='small' /></TableCell>
                <TableCell align="right">
                  <IconButton className={classes.button} aria-label="delete" onClick={ (e) => { e.stopPropagation(); props.handleDeleteClick(props.entity) }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>;
};

export default EntityRow;