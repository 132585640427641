import axios from 'axios';

import { VESTIGIUM_BASE_URL } from '../settings';

import FusionAuthService from '../services/fusionAuthService/index';

class ProfileService {

  constructor() {
    this.fetchProfileStatus = this.fetchProfileStatus.bind(this);
  }

  getHeaders() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${FusionAuthService.getAccessToken()}`,
    };
  }

  _handleResponse(response) {
    return response.data;
  }

  _handleError(error) {
    console.log(error);
    if (error.response) {
      throw error.response.data.error;
    } else {
      throw new Error('API Error');
    }
  }

  fetchProfileGroups() {
    const options = {
      method: 'GET',
      url: VESTIGIUM_BASE_URL + `/legacy/profile_groups`,
      headers: this.getHeaders()
    };

    return axios(options)
      .then(this._handleResponse)
      .catch(this._handleError);
  }

  fetchProfiles() {
    const options = {
      method: 'GET',
      url: VESTIGIUM_BASE_URL + `/legacy/profiles`,
      headers: this.getHeaders()
    };

    return axios(options)
      .then(this._handleResponse)
      .catch(this._handleError);
  }

  fetchProfileStatus(id) {
    const options = {
      method: 'GET',
      url: VESTIGIUM_BASE_URL + `/legacy/profile_statuses/${id}`,
      headers: this.getHeaders()
    };

    return axios(options)
      .then(this._handleResponse)
      .catch(this._handleError);
  }

  updateProfile(id, params) {
    const options = {
      method: 'PATCH',
      url: VESTIGIUM_BASE_URL + `/legacy/profiles/${id}`,
      headers: this.getHeaders(),
      data: params
    };

    return axios(options)
      .then(this._handleResponse)
      .catch(this._handleError);
  }

  destroyEntity(profile_id, entity_id) {
    const options = {
      method: 'DELETE',
      url: VESTIGIUM_BASE_URL + `/legacy/profiles/${profile_id}/entities/${entity_id}`,
      headers: this.getHeaders()
    };

    return axios(options)
      .then(this._handleResponse)
      .catch(this._handleError);
  }

}

export default new ProfileService();
