import { Route, Routes } from 'react-router-dom';
import Login from "../main/login/Login";
import Logout from "../main/logout/logout";
import Callback from "../main/callback/Callback";
import Header from '../components/Header.js';

import PrivateRoute from '../hocs/PrivateRoute';
import Index from './Index';
import Profile from './Profile';
import ProfileGroup from './ProfileGroup';
import Welcome from '../main/welcome/Welcome';

export default function Entry() {
  
  return(
    <div className="App">
      <Header />
      <div className="AppBody">      
        <Routes>  
          <Route exact path="/welcome" element={<Welcome />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/logout" element={<Logout />} />
          <Route exact path="/callback" element={<Callback />} />

          <Route path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<Index />} />            
            <Route exact path="/group/:profile_group_id" element={<ProfileGroup />} />
            <Route exact path="/profiles/:profile_id" element={<Profile />} />
          </Route>

        </Routes>
      </div>
    </div>);

}