import { createSlice, createAction } from "@reduxjs/toolkit"

import ProfileGroupsNormalizer from '../normalizers/profile_groups';

export const profileGroupsFetchStarted = createAction('profileGroups/fetchStarted');
export const profileGroupsFetchFailed = createAction('profileGroups/fetchFailed');

const profileGroupsSlice = createSlice({
  name: 'profileGroups',
  initialState: { current: null, entities: { profileGroups: {}, profiles: {} }, result: [] },
  reducers: {
    saveProfileGroups: (state, action) => { 
      return ProfileGroupsNormalizer.normalize(action.payload);
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = profileGroupsSlice
// Extract and export each action creator by name
export const { saveProfileGroups } = actions
// Export the reducer, either as a default or named export
export default reducer