import { createSlice, createAction } from "@reduxjs/toolkit"

export const profileStatusFetchStarted = createAction('profile_status/fetchStarted');
export const profileStatusFetchFailed = createAction('profile_status/fetchFailed');

const profileStatusSlice = createSlice({
  name: 'profile_status',
  initialState: null,
  reducers: {
    saveProfileStatus: (state, action) => { return action.payload },
    clearProfileStatus: (state, action) => { return null }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = profileStatusSlice
// Extract and export each action creator by name
export const { saveProfileStatus, clearProfileStatus } = actions
// Export the reducer, either as a default or named export
export default reducer