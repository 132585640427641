import Typography from "@material-ui/core/Typography";
import LogInOut from "../../components/LogInOut";

function Welcome() {
  return (
    <>
      <Typography
        variant="h3"
        color="inherit"
        className="font-semibold leading-tight"
      >
        Welcome to Vestigium
        <br />
      </Typography>
      <LogInOut />
    </>
  );
}

export default Welcome;
