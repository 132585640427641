import axios from 'axios';
import { subDays } from 'date-fns'

import { VESTIGIUM_BASE_URL } from '../settings';
import FusionAuthService from './fusionAuthService';

class TimeseriesDataService {

  constructor() {
    this.getSeries = this.getSeries.bind(this);
  }

  getHeaders() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${FusionAuthService.getAccessToken()}`,
    };
  }

  _handleResponse(response) {
    return response.data;
  }

  _handleError(error) {
    console.log(error);
    if (error.response) {
      throw error.response.data.error;
    } else {
      throw new Error('API Error');
    }
  }

  getSeries(id, queryOptions) {
    let fromDate = subDays(new Date(), 3).toISOString();
    const options = {
      method: 'GET',
      url: VESTIGIUM_BASE_URL + `/legacy/ts/profiles/${id}`,
      params: {
        cols: 'status',
        aggregate: 'min',
        interval_unit: 1,
        interval_measure: 'hour',
        from: fromDate
      },
      headers: this.getHeaders()
    };

    return axios(options)
      .then(this._handleResponse)
      .catch(this._handleError);
  }

  getPresenceSeries(id, queryOptions) {
    let fromDate = subDays(new Date(), 3).toISOString();
    const options = {
      method: 'GET',
      url: VESTIGIUM_BASE_URL + `/legacy/ts/profiles/${id}/presence`,
      params: {
        from: fromDate
      },
      headers: this.getHeaders()
    };

    return axios(options)
      .then(this._handleResponse)
      .catch(this._handleError);
  }

}

export default new TimeseriesDataService();