import React from 'react';

import pluralize from 'pluralize';

import { makeStyles } from '@material-ui/core/styles';
import EntityRow from "./EntityRow";
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import DeleteIcon from '@material-ui/icons/Delete';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Slide from '@material-ui/core/Slide';

import Entity from './Entity';
import { getColor } from '../utils/EntityStatus';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles(theme => ({
  
  schemaGroup: {
    marginTop: 30,
    marginBottom: 20
  },

  schema: {
    border: 4,
    borderRadius: 3,
    borderColor: "blue",
    padding: 20,
    margin: 20,
    backgroundColor: "white"
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },

}));



function SchemaGroup(props) {
  const classes = useStyles(props);


  const [hasCurrentEntity, setHasCurrentEntity] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [currentEntity, setEntity] = React.useState(null);

  function handleSelectEntity(entity) {
    setEntity(entity);
    setHasCurrentEntity(true);
  }

  function handleDeleteClick(entity) {
    setEntity(entity);
    setOpenDeleteDialog(true);
  }

  function handleCloseEntityDialog(){
    setHasCurrentEntity(false);
    setEntity(null);
  }

  function handleCloseDeleteDialog() {
    setOpenDeleteDialog(false);
  }

  function handleDelete() {
    console.log(currentEntity);
    props.destroyEntity(props.profile_id, currentEntity.id)
    setOpenDeleteDialog(false);
    setEntity(null);
  }

  return <div className={classes.schemaGroup} >
    <Typography variant="h4" gutterBottom>{pluralize(props.schema_name)}</Typography>

    <Paper className="tableRoot">
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "40%" }}>Name</TableCell>
              <TableCell align="center">Last seen</TableCell>
              <TableCell align="center">Online?</TableCell>
              <TableCell align="center">Compliance</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.entities.map(entity => (
              // <TableRow key={entity.identifier}>
              //   <TableCell component="th" scope="row">{entity.identifier}</TableCell>
              //   <TableCell align="right">{lastSeen(entity)}</TableCell>
              //   <TableCell align="right"><BooleanIcon check={entity.online} /></TableCell>
              //   <TableCell align="right"><BooleanIcon check={entity.compliant} /></TableCell>
              //   <TableCell align="right">
              //     <IconButton className={classes.button} aria-label="delete" onClick={handleClickOpen}>
              //       <DeleteIcon />
              //     </IconButton>
              //   </TableCell>
              // </TableRow>
              <EntityRow key={entity.id} entity={entity} handleClickOpen={handleSelectEntity} handleDeleteClick={handleDeleteClick} />
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete this entity?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this entity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      { hasCurrentEntity && 
      <Dialog fullWidth={true} 
              maxWidth={'lg'} 
              open={hasCurrentEntity} 
              onClose={handleCloseEntityDialog} 
              TransitionComponent={Transition} 
              style={{paddingBottom: 100}}
              BackdropProps={{ style: { backgroundColor: "black", opacity: 0.8 } }} >
          <Toolbar style={{backgroundColor: getColor(currentEntity) }}>
            <IconButton edge="start" color="inherit" onClick={handleCloseEntityDialog} aria-label="close">
              <CloseIcon />
            </IconButton>
            <IconButton className={classes.button} aria-label="delete" onClick={ handleDelete }>
                    <DeleteIcon />
                  </IconButton>
            <Typography variant="h6" className={classes.title}>
              {currentEntity.name || currentEntity.identifier }
            </Typography>
            {/* <Button align="right" autoFocus color="inherit" onClick={handleCloseEntityDialog}>
              Delete
            </Button> */}
          </Toolbar>
        <Entity entity={currentEntity} />
      </Dialog>}


 {/* <Grid container item xs={12}
                direction={"row"}
                alignContent={"flex-start"}
                spacing={1}
                className={classes.schema} >
    { props.entities.map( (entity) => <Entity key={entity.id} profile_id={props.profile_id} entity={entity} destroyEntity={props.destroyEntity} /> ) }
  </Grid>*/}
  </div>;
};

export default SchemaGroup;
