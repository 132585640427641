export const COLORS = {
  DEEP_RED: "#cd0000",
  DEEP_GREEN: "#556b2f",
  PALE_GREEN: '#c5e1a5',
  PALE_ORANGE: '#ff9d5c',
  PALE_RED: '#ef7564',
  AMBER: "darkorange",
  BRIGHT_GREEN: '#138808',
  DEEP_PURPLE: '#482980',
  LIGHT_GREY: '#C0C0C0',
  DIRTY_PINK: '#FDBCBC'
}

