import React, { useState, useEffect } from "react";

import fusionAuthService from "../../../services/fusionAuthService";

const FusionAuthLogin = () => {
  const [authUrl, setAuthUrl] = useState(null);

  useEffect(() => {
    if (authUrl == null) {
      fusionAuthService.getAuthorizationURL().then((data: any) => {
        setAuthUrl(data.auth_url);
      });
    }
  }, [authUrl, setAuthUrl]);

  const redirect = () => {
    window.location.replace(authUrl);
    return <></>;
  };

  return authUrl ? redirect() : <></>;
};

export default FusionAuthLogin;
