import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel'; 
import Switch from '@material-ui/core/Switch'; 

import SchemaGroup from './SchemaGroup';
import TimeseriesDataService from '../services/TimeseriesDataService';
import CircularProgress from '@material-ui/core/CircularProgress';

import Graph from './Graph';
import EventGraph from './EventGraph';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "whitesmoke",
  },
  mainContainer: {
    margin: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch"
  },
  schemas: {
    // width: "70%",
  },
  schemaTable: {
    marginTop: 50
  },
  topBanner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));


export default function ProfileDashboard(props) {
  const [series, setSeries] = useState();
  const [presenceSeries, setPresenceSeries] = useState();

  useEffect(() => {    
    TimeseriesDataService.getSeries(props.profile.id).then((data) => {
      
      if(data['points'].length > 0){        
        const xSeries = []
        const ySeries = []
        data.points.forEach( (p) => {
          xSeries.push(parseInt(p[0].substr(3)) * 300);
          ySeries.push(p[1])
        })
        const series = [xSeries, ySeries];
        setSeries(series);      
      }
    });    

    TimeseriesDataService.getPresenceSeries(props.profile.id).then((data) => {
      const processedData = data.map((point) => { 
        const starts = new Date(point.starts);
        const ends = new Date(point.ends);
    
        return {
          timeRange: [starts, ends],
          val: (point.online ? 'Online' : 'Offline')
        }
      });
    
      const presenceData = [
        {
          group: "",
          data: [
            {
              label: "",
              data: processedData
            },
          ],
        }
      ]
      setPresenceSeries(presenceData);      
    });


  }, [props.profile.id]);

  const handleSwitchProfileDormancy = (event) => {
    props.updateProfile(props.profile.id, { dormant: !event.target.checked})
  }
  
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.topBanner}>
        <h1>{props.profile.name}</h1>
        <div>
          <FormControlLabel control={
                              <Switch checked={!props.profile.dormant}
                                      onChange={handleSwitchProfileDormancy}
                                    inputProps={{ 'aria-label': 'controlled' }} />
                            } label="Is Active?" />
        </div>
      </div>
        { presenceSeries ? <EventGraph title="Profile Presence" series={presenceSeries} /> : <CircularProgress size={42} thickness={3} /> }

        { series ? <Graph data={series} title="Profile Status" /> : <CircularProgress size={42} thickness={3} /> }

        <div className={classes.schemas}>
          {
            Object.keys(props.groupedEntities).map(function (schema_name) {
              var entities = props.groupedEntities[schema_name];
              return <SchemaGroup key={schema_name} className={classes.schemaTable} profile_id={props.profile.id} schema_name={schema_name} entities={entities} destroyEntity={props.destroyEntity} />;
            })
          }
      </div>
    </div>
  );
}