import React, { Component } from 'react';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import store from './redux';

import './App.css';
import './icons';
import Entry from './containers/Entry';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter forceRefresh={false}>
            <Entry />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
