import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import formatDistanceToNow from 'date-fns/formatDistanceToNow';
// import isValid from 'date-fns/isValid';
// import parseISO from 'date-fns/parseISO';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Rating from '@material-ui/lab/Rating';

// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import ErrorIcon from '@material-ui/icons/Error';

import { getProfileGroups } from '../selectors/profile_groups';

import {  getAllProfiles } from '../selectors/profiles';
import * as actionCreators from '../actions/actionCreators';

const Index = ({profileGroups, profiles, fetchProfileGroups, 
  profilesCount, onlineProfilesCount, offlineProfilesCount, healthyProfilesCount, 
  degradedProfilesCount, severeDegradedProfilesCount, criticalProfilesCount, severeCriticalProfilesCount}) => {

  useEffect(() => {    
    fetchProfileGroups();
  }, [fetchProfileGroups]);

  // const timeSince = (_time) => {
  //    let pastTime = parseISO(_time);
  //     if(!isValid(pastTime)){
  //       return "Invalid Date";
  //     } else {
  //       return formatDistanceToNow(pastTime);
  //     }
  // }

  // const renderBooleanIcon = (check) => {
  //   return check ? <CheckCircleIcon style={{color: "green"}} /> : <ErrorIcon color="error" /> ;
  // }

  // const renderPresenceStatus = (online, last_presence_change_at) => {
  //   if(online === null){
  //     return "Never seen";
  //   }else if(last_presence_change_at !== null){
  //     return <span>{ renderBooleanIcon(online) } for {timeSince(last_presence_change_at)}</span>;
  //   } else {
  //     return "Unknown";
  //   }
  // }

  // const renderStatus = (status) => {
  //   if(status === null){ status = 0 };
  //   return <Rating name="size-large" value={status} size="large" readOnly />;
  // }

  const onlineOfflineCount = (profileGroup) => {
    const _online = profileGroup.profiles.filter(p => {      
      const prof = profiles.find(prof => prof.id === p);
      if(prof === undefined){
        return false;
      }else{
        return prof.online === true;
      }
    }).length;
    return [_online, profileGroup.profiles.length-_online];
  }

  const selectGroup = (profileGroup) => {
    return (<Link to={`/group/${profileGroup.id}`}>{profileGroup.name}</Link>);
  }

  const renderStatuses = () => {
    return (
    <><Grid container>
        <Grid item xs={4} sm={2} md={2} lg={2}>
          <Paper className="paper">
            <Typography color="primary" className="countHeading" variant="h4">Total</Typography>
            <Typography color="primary" className="heading" variant="h3">{profilesCount}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={4} sm={2} md={2} lg={2}>
          <Paper className="paper">
            <Typography className="countHeading" variant="h4">Online</Typography>
            <Typography className="heading" variant="h3">{onlineProfilesCount}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={4} sm={2} md={2} lg={2}>
          <Paper className="paper">
            <Typography className="countHeading" variant="h4">Offline</Typography>
            <Typography className="heading" variant="h3">{offlineProfilesCount}</Typography>
          </Paper>
        </Grid>
      </Grid><Grid container>
          <Grid item xs={4} sm={2} md={2} lg={2}>
            <Paper className="paper">
              <Typography className="countHeading" variant="h4">Healthy</Typography>
              <Typography className="heading" variant="h3">{healthyProfilesCount}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={2} md={2} lg={2}>
            <Paper className="paper">
              <Typography className="countHeading" variant="h4">Degraded</Typography>
              <Typography className="heading" variant="h3">{degradedProfilesCount}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={2} md={2} lg={2}>
            <Paper className="paper">
              <Typography className="countHeading" variant="h4">Severe Degraded</Typography>
              <Typography className="heading" variant="h3">{severeDegradedProfilesCount}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={2} md={2} lg={2}>
            <Paper className="paper">
              <Typography className="countHeading" variant="h4">Critical</Typography>
              <Typography className="heading" variant="h3">{criticalProfilesCount}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={2} md={2} lg={2}>
            <Paper className="paper">
              <Typography className="countHeading" variant="h4">Severe Critical</Typography>
              <Typography className="heading" variant="h3">{severeCriticalProfilesCount}</Typography>
            </Paper>
          </Grid>
        </Grid></>);
  }

  const renderProfileGroups = () => {
    return <Paper className="tableRoot">
      <Table className="table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "70%" }}>Name</TableCell>
            <TableCell style={{ width: "10%" }} align="right">Total</TableCell>
            <TableCell style={{ width: "10%" }} align="right">Online</TableCell>
            <TableCell style={{ width: "10%" }} align="right">Offline</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(profileGroups).map( (profileGroup) => {
            const [online, offline] = onlineOfflineCount(profileGroup);

            return (<TableRow key={profileGroup.id}>
              <TableCell component="th" scope="row">{selectGroup(profileGroup)}</TableCell>
              <TableCell align="right">{profileGroup.profiles.length}</TableCell>
              <TableCell align="right" style={{ verticalAlign: 'center' } } >{online}</TableCell>
              <TableCell align="right">{offline}</TableCell>
            </TableRow>);
          })}
        </TableBody>
      </Table>
    </Paper>;
  }


  return  (<div  className="indexContainer" >
      {false && renderStatuses()}
      
      {renderProfileGroups()}
    </div>);

}

const mapStateToProps = (state, ownProps) => {
  return {
    profileGroups: getProfileGroups(state),
    profiles: getAllProfiles(state),
  };
};


export default connect(mapStateToProps, actionCreators)(Index);