const isProd = process.env.NODE_ENV === "production";
const useLiveServers = true;

const production = {
  lumin_host: "https://api.mylumin.net",
  vestigium_base_url: 'https://vestigium2.prod.mylumin.net'
};

const localDevelopment = {
  lumin_host: "http://localhost:3333",
  vestigium_base_url: 'http://localhost:8888'
};

const settings = isProd || useLiveServers ? production : localDevelopment;

export const AUTH_CONFIG = {
  domain: "https://auth.prod.mylumin.net",
};

export const APP_URL = isProd ? 'https://v-dash.prod.mylumin.net' : 'http://localhost:3000';

export const PRODUCTION_LUMIN_HOST = "https://vestigium2.prod.mylumin.net";
export const LUMIN_HOST = settings.lumin_host;
export const VESTIGIUM_BASE_URL = settings.vestigium_base_url;