import { createSelector } from 'reselect'
import { groupBy } from 'lodash';
// const getOnlineFilter = (state) => state.onlineFilter
export const getProfileStatus = (state) => { return state.profile_status }

export const getEntityStatuses = createSelector(
  [getProfileStatus], 
  (profileStatus) => { 
    if(profileStatus === null){
      return [];
    }
    return profileStatus.entities
  }
);

export const getGroupedEntities = createSelector([getEntityStatuses], (entity) => { return groupBy(entity, 'schema_name') });