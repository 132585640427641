import { createSlice, createAction } from "@reduxjs/toolkit"

export const profilesFetchStarted = createAction('profiles/fetchStarted');
export const profilesFetchFailed = createAction('profiles/fetchFailed');

const profilesSlice = createSlice({
  name: 'profiles',
  initialState: [],
  reducers: {
    saveProfiles: (state, action) => { return action.payload; }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = profilesSlice
// Extract and export each action creator by name
export const { saveProfiles } = actions
// Export the reducer, either as a default or named export
export default reducer