import { createSelector } from 'reselect'

export const getSelectedProfileGroup = (state) => {
  return state.profileGroups.entities.profileGroups[state.ui.currentProfileGroup];
}

export const getAllProfiles = (state) => {
  return Object.values(state.profileGroups.entities.profiles);
}

export const getProfiles = (state) => {
  const selectedProfileGroup = state.profileGroups.entities.profileGroups[state.ui.currentProfileGroup];
    if(selectedProfileGroup === null || selectedProfileGroup === undefined){
      return Object.values(state.profileGroups.entities.profiles);
    }else{
      const filtered = Object.keys(state.profileGroups.entities.profiles)
        .filter(key => selectedProfileGroup.profiles.includes(key))
        .reduce((obj, key) => {
          obj[key] = state.profileGroups.entities.profiles[key];
          return obj;
        }, {});
        return Object.values(filtered);
    }
}


export const getActiveProfiles = createSelector(
  [ getProfiles ],
  (profiles) => {
    return profiles.filter(profile => !profile.dormant)
  }
)

export const getDormantProfiles = createSelector(
  [ getProfiles ],
  (profiles) => {
    return profiles.filter(profile => profile.dormant)
  }
)

export const getOnlineProfiles = createSelector(
  [ getActiveProfiles ],
  (profiles) => {
    return profiles.filter(profile => profile.online)
  }
)

export const getOfflineProfiles = createSelector(
  [ getActiveProfiles ],
  (profiles) => {
    return profiles.filter(profile => !profile.online)
  }
)

export const getHealthyProfiles = createSelector(
  [ getActiveProfiles ],
  (profiles) => {
    return profiles.filter(profile => profile.status === 5)
  }
)

export const getDegradedProfiles = createSelector(
  [ getActiveProfiles ],
  (profiles) => {
    return profiles.filter(profile => profile.status === 4)
  }
)

export const getSevereDegradedProfiles = createSelector(
  [ getActiveProfiles ],
  (profiles) => {
    return profiles.filter(profile => profile.status === 3)
  }
)

export const getCriticalProfiles = createSelector(
  [ getActiveProfiles ],
  (profiles) => {
    return profiles.filter(profile => profile.status === 2)
  }
)

export const getSevereCriticalProfiles = createSelector(
  [ getActiveProfiles ],
  (profiles) => {
    return profiles.filter(profile => profile.status === 1)
  }
)

export const getProfilesCount = createSelector(
  [ getProfiles ], (profiles) => { return profiles.length }
)

export const getActiveProfilesCount = createSelector(
  [ getActiveProfiles ], (profiles) => { return profiles.length }
)

export const getDormantProfilesCount = createSelector(
  [ getDormantProfiles ], (profiles) => { return profiles.length }
)

export const getOnlineProfilesCount = createSelector(
  [ getOnlineProfiles ], (profiles) => { return profiles.length }
)

export const getOfflineProfilesCount = createSelector(
  [ getOfflineProfiles ], (profiles) => { return profiles.length }
)

export const getHealthyProfilesCount = createSelector(
  [ getHealthyProfiles ], (profiles) => { return profiles.length }
)
export const getDegradedProfilesCount = createSelector(
  [ getDegradedProfiles ], (profiles) => { return profiles.length }
)
export const getSevereDegradedProfilesCount = createSelector(
  [ getSevereDegradedProfiles ], (profiles) => { return profiles.length }
)
export const getCriticalProfilesCount = createSelector(
  [ getCriticalProfiles ], (profiles) => { return profiles.length }
)
export const getSevereCriticalProfilesCount = createSelector(
  [ getSevereCriticalProfiles ], (profiles) => { return profiles.length }
)