import React from 'react';

// import { ResponsiveLine } from '@nivo/line'
import uPlot from 'uplot';
import UplotReact from 'uplot-react';
import 'uplot/dist/uPlot.min.css';
import useWindowSize from "../hooks/useWindowSize";
import Paper from '@material-ui/core/Paper';


const Graph  = ({data}) => {
  const size = useWindowSize();
  
  const options = {
    width: size.width-75,
    height: 250,
    cursor: {
      points: {
        size:   (u, seriesIdx)       => u.series[seriesIdx].points.size * 2.5,
        width:  (u, seriesIdx, size) => size / 4,
        stroke: (u, seriesIdx)       => u.series[seriesIdx].points.stroke(u, seriesIdx) + '90',
        fill:   (u, seriesIdx)       => "#fff",
        x: false,
  	    y: false,
      },
      sync: {
        key: 0,
      }
    },
    series: [
      {},
      {
        label: "Snapshots",
        width: 0.5,
        stroke: "#007aa5",
        fill:   "#007aa51A",
        paths: uPlot.paths.bars({align: 0})
      },
    ],
    scales: {
      y: {
        min: 0,
        max: 5
      },
    },
    legend: {
      show: false
    },
    axes: [
      {
        stroke: "slategrey",
      	font: `12px 'Roboto'`,
      	labelFont: `12px 'Roboto'`,
        grid: {
          show: false,          
        },
        ticks: {
          width: 1 ,
          stroke: "#2c3235",
        }
      },
      {
        stroke: "slategrey",
        font: `14px 'Roboto'`,
      	labelFont: `12px 'Roboto'`,
        grid: {
          width: 1 ,
          stroke: "#eee5de",
          dash: [5, 5],
        },
        ticks: {
          width: 1 ,
          stroke: "#eee5de",
        }
      },
    ],
  };
  


  return ( 
    <Paper className="paper" style={{marginLeft: 0, marginRight: 0, marginTop: 50, marginBottom: 25, padding: 30 }} >
      <div >
        <UplotReact
        options={options}
        data={data}
        onCreate={(chart) => {}}
        onDelete={(chart) => {}}
    />
        {/* <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        // yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          // format: '%b %d',
          tickValues: 'every day',
          legend: 'time scale',
          legendOffset: -12,
      }}
        // axisBottom={{
        //     orient: 'bottom',
        //     tickSize: 5,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: 'time',
        //     legendOffset: 36,
        //     legendPosition: 'middle'
        // }}
        // axisLeft={{
        //     orient: 'left',
        //     tickSize: 5,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: 'count',
        //     legendOffset: -40,
        //     legendPosition: 'middle'
        // }}
        colors={{ scheme: 'accent' }}
        pointSize={1}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={1}
        pointBorderColor={{ from: 'serieColor' }}
        // pointLabelYOffset={-12}
        // enableArea={true}
        // areaOpacity={1}
        useMesh={true}
        xFormat="time:%Y-%m-%d"
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 90,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    /> */}
      </div>
      </Paper>
    );
}

export default Graph;